'use client';

import toast, { Toaster } from 'react-hot-toast';

type NotificationsProps = React.ComponentProps<typeof Toaster>;

export function Notifications(props: NotificationsProps) {
  // TODO: Error boundary?
  return <Toaster {...props} />;
}

const notification = toast;

export default notification;

import Link from 'next/link';

import { Container } from '@/components/Container';
import { FadeIn } from '@/components/FadeIn';
import { Logo, SaplingLogo } from '@/components/Logo';
import { footer } from '@/menus/footer';
import { NewsletterForm } from './client/NewsletterForm';

function Navigation() {
  return (
    <nav>
      <ul role="list" className="grid grid-cols-2 gap-8 sm:grid-cols-3">
        {footer.map((section) => (
          <li key={section.title}>
            <div className="font-display text-sm font-semibold tracking-wider text-neutral-950">
              {section.title}
            </div>
            <ul role="list" className="mt-4 text-sm text-neutral-700">
              {section.links.map((link) => (
                <li key={link.href} className="mt-4">
                  <Link
                    href={link.href}
                    className="transition hover:text-neutral-950"
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export function Footer() {
  return (
    <Container as="footer" className="mt-24 w-full sm:mt-32 lg:mt-40">
      {/* <FadeIn> */}
      {/* <div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
          <Navigation />
          <div className="flex lg:justify-end">
            <NewsletterForm />
          </div>
        </div> */}
      <div className="mb-20 mt-24 flex flex-wrap items-end justify-between gap-x-6 gap-y-4 border-t border-neutral-950/10 pt-12">
        <Link href="/" aria-label="Home">
          {/* <Logo className="h-8" fillOnHover /> */}
          <SaplingLogo className="h-10" fillOnHover />
        </Link>
        <p className="text-sm text-neutral-700">
          © Sapling AB {new Date().getFullYear()}
        </p>
      </div>
      {/* </FadeIn> */}
    </Container>
  );
}

import { socialMediaProfiles } from '@/components/SocialMedia';

export const footer = [
  // {
  //   title: 'Work',
  //   links: [
  //     { title: 'FamilyFund', href: '/work/family-fund' },
  //     { title: 'Unseal', href: '/work/unseal' },
  //     { title: 'Phobia', href: '/work/phobia' },
  //     {
  //       title: (
  //         <>
  //           See all <span aria-hidden="true">&rarr;</span>
  //         </>
  //       ),
  //       href: '/work',
  //     },
  //   ],
  // },
  {
    title: 'Företaget',
    links: [
      { title: 'Om oss', href: '/om-oss' },
      { title: 'Bloggen', href: '/blogg' },
      { title: 'Kontakta oss', href: '/kontakta-oss' },
    ],
  },
  {
    title: 'Sociala medier',
    links: socialMediaProfiles,
  },
] as const;
